import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import * as actions from "../../../store/actions/index";
import Tooltip from "../../shared/UI/Tooltip/Tooltip";
import { removeDuplicates } from "../../shared/utility";
import DropdownSuggestions from "../../dropdownSuggestions/DropdownSuggestions.js";
import "./LiveShotSignals.css";
import { ReactComponent as InBoundPlusIcon } from "../../../assets/icons/inbound-add-outline.svg";
import { ReactComponent as MinusLogo } from "../../../assets/icons/remove-icon-outline.svg";
import { ReactComponent as InfoLiveShotOutlineLogo } from "../../../assets/icons/Info-liveshots-outline.svg";
import { ReactComponent as OutboundLogo } from "../../../assets/icons/outbound-icon.svg";
import { getPathClassName } from "../../shared/utility";
import { CustomInput } from "../../shared/UI/Input/CustomInput.js";
import ClickAwayListenerHoc from "../../shared/UI/ClickAwayListenerHoc.js";
import { outBoundTypeArray } from "../../../constants/ColumnHeaderArray.js";

const OutboundSignals = (props) => {
  const {
    isDisable,
    liveShotId,
    formInfo,
    abstractLiveshotId,
    isArmRouting,
    userId,
    deleteSignal,
    currentRoom,
    userList,
    token,
    liveshotStatus,
    redBorder,
    onAddSignal,
    inBoundFields,
    commsFields,
    outBoundButton,
    outBoundCRPath,
    onUpdateLiveShotSignals,
    onChangeSignal,
    onUpdateNormalLiveShotSignals,
    outBoundGlobalPath,
    availOutBoundSharedSignals,
    sharedOutBoundGlobalPath,
    showCommonPopupModal,
    onCheckAvailableSignals,
    currentCanvas,
    onUpdateUniLateral,
    unOwnLiveshotSignals,
    liveShotSignalSelected,
    onTakeAndGrab,
    userFullName,
    showDuplicateLocalPathModal,
    onCheckOwnership,
    onRemoveRedBorder,
    outBoundFields,
    setOutBoundFields,
    confirmDuplicateRoute,
    checkForDuplicateLocalPaths,
    updateInfo,
    hideDropdownOnClickOutside,
    isWindowTimeInvalid,
  } = props;

  let outBound_crPath_classes = [];
  let outBound_glPath_classes = [];

  const outBoundTypeMenu = useRef([]);

  const [isUpdating, setIsUpdating] = useState(false);
  const [outboundRows, setOutboundRows] = useState([]);
  const [outBoundFieldsNormal, setOutBoundFieldsNormal] = useState([]);
  const [type, setStype] = useState(0);

  useEffect(() => {
    const outboundArray = [];
    const outbound = removeDuplicates(liveShotSignalSelected).filter(
      (signal) =>
        signal.abstractLiveshotId === abstractLiveshotId &&
        signal.liveshotSignalTypeId === 2 &&
        signal.liveshotId === liveShotId
    );
    setOutBoundFields(outbound);
    updateOutBoundRows(outbound);
    for (let key in outbound) {
      outboundArray.push(outbound[key].liveshotSignalId);
    }
    setOutBoundFieldsNormal(
      removeDuplicates(liveShotSignalSelected).filter(
        (signal) =>
          signal.liveshotId === liveShotId &&
          signal.abstractLiveshotId === 0 &&
          outboundArray.includes(signal.abstractLiveshotSignalId)
      )
    );
  }, [liveShotSignalSelected, liveShotId, abstractLiveshotId]);

  useEffect(() => {
    if (
      outBoundFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in outBoundFields) {
        const signal = outBoundFields[key];
        if (
          signal.globalPathName &&
          sharedOutBoundGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    outBoundFields,
    sharedOutBoundGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    let abSignals = [];
    outBoundFields.forEach((fields) => {
      let crPathNameNormal = outBoundFieldsNormal.find(
        (inboundNormal) =>
          inboundNormal.abstractLiveshotSignalId === fields.liveshotSignalId
      );
      if (crPathNameNormal === undefined) {
        abSignals.push(fields);
      }
    });
  }, [
    token,
    isDisable,
    outBoundFieldsNormal,
    outBoundFields,
    liveShotId,
    userId,
    currentRoom,
  ]);

  const updateOutBoundRows = (outbound) => {
    let updatedRows = [...outboundRows];
    outbound &&
      outbound.map((item, index) => {
        let crPath = outBoundFieldsNormal.find(
          (outboundNormal) =>
            outboundNormal.abstractLiveshotSignalId === item.liveshotSignalId
        );

        let tx = "";

        if (item.uniLateral) {
          tx = item.tx ? item.tx : "";
        } else {
          tx = crPath ? (crPath.tx ? crPath.tx : "") : "";
        }
        updatedRows[index] = {
          ...updatedRows[index],
          isShowOutboundTypeMenu: false,
          outboundTypePlaceholder: "SELECT",
          pathName: item.pathName,
          globalPathName: item.globalPathName,
          crPathName: item.crPathName,
          tx: tx,
          uniLateral: item.uniLateral,
          routed: item.routed,
        };
      });

    setOutboundRows(updatedRows);
  };

  const removeOutboundRow = (idx) => {
    let updatedRows = [...outboundRows];
    let outBoundFieldsRows = [...outBoundFields];

    updatedRows.splice(idx, 1);
    outBoundFieldsRows.splice(idx, 1);

    setOutBoundFields(outBoundFieldsRows);
    setOutboundRows(updatedRows);
  };

  const setSignals = (signal, i, signalName) => {
    const outBoundValues = [...outBoundFields];
    const normalValuesOutBound = [...outBoundFieldsNormal];

    if (signalName === "crPathOutBound") {
      outBoundValues[i].crPathName = signal;
      outBoundValues[i].crSuggest = "false";
      outBoundValues[i].updatedBy = parseInt(userId);
      outBoundValues[i].crPathNameSelected = "true";
      outboundRows[i].crPathName = signal;
      outboundRows[i].routed = false;
      outBoundValues[i].routed = false;
      outBoundValues[i].crPathName = signal;
      outBoundValues[i].crKey = !!outboundRows[i].crKey;
      setOutBoundFields(outBoundValues, outboundRows[i].crPathName);
      setOutBoundFieldsNormal(outBoundValues);
      const normalObj = {};
      normalObj.liveshotSignalId = outBoundValues[i].liveshotSignalId;
      normalObj.roomId = currentRoom.roomId;
      normalObj.updatedBy = parseInt(userId);
      normalObj.crPathName = signal;
      onUpdateLiveShotSignals(
        token,
        outBoundValues[i],
        { crPathName: outboundRows[i].crPathName, routed: false },
        currentRoom
      );
    }
    if (signalName === "globalPathOutBound") {
      if (outBoundValues[i].uniLateral) {
        outBoundValues[i].updatedBy = parseInt(userId);
        outBoundValues[i].globalPathName = signal;
        outBoundValues[i].globalSuggest = "false";
        outBoundValues[i].globalPathNameSelected = "true";
        outBoundValues[i].roomId = currentRoom.roomId;
        outBoundValues[i].routed = false;
        outboundRows[i].globalPathName = signal;
        outboundRows[i].routed = false;
        outBoundValues[i].globalKey = !!outboundRows[i].globalKey;
        setOutBoundFields(outBoundValues, outboundRows[i].globalPathName);
        onUpdateLiveShotSignals(
          token,
          outBoundValues[i],
          { globalPathName: outboundRows[i].globalPathName, routed: false },
          currentRoom
        );
      } else {
        const normalObj = {};
        normalObj.liveshotSignalId = normalValuesOutBound[i].liveshotSignalId;
        normalObj.globalPathName = signal;
        normalObj.roomId = currentRoom.roomId;
        normalObj.updatedBy = parseInt(userId);
        onUpdateNormalLiveShotSignals(token, normalObj, currentRoom);
        outBoundValues[i].globalSuggest = "false";
        outBoundValues[i].globalPathNameSelected = "true";
        outBoundValues[i].globalKey = !!outboundRows[i].globalKey;
        setOutBoundFields(outBoundValues);
        normalValuesOutBound[i].globalPathName = signal;
        setOutBoundFieldsNormal(normalValuesOutBound);
      }
    }

    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const hideOutboundTypes = (idx) => {
    let updatedRows = [...outboundRows];
    let outboundTypePlaceholder = "SELECT";

    let isShowOutboundTypeMenuValue = updatedRows[idx].isShowOutboundTypeMenu;
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowOutboundTypeMenu: !isShowOutboundTypeMenuValue,
      outboundTypePlaceholder: outboundTypePlaceholder,
    };
    setOutboundRows(updatedRows);
  };

  const onChangeOutboundType = (e, idx) => {
    var a = document.querySelectorAll("li.ul-list-item");
    for (var i = 0; i < a.length; i++) {
      a[i].classList.remove("list-item-bg");
    }
    e.target.classList.add("list-item-bg");
    let outboundTypeValue = e.target.innerHTML;

    let values = [...outBoundFields];
    values[idx].type = outboundTypeValue;
    values[idx].updatedBy = parseInt(userId);
    let updatedRows = [...outboundRows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      type: outboundTypeValue,
      isShowOutboundTypeMenu: false,
    };
    setOutboundRows(updatedRows);

    onUpdateLiveShotSignals(
      token,
      values[idx],
      { type: outboundTypeValue },
      currentRoom
    );
    setOutBoundFields(values);
    onChangeSignal(inBoundFields, values, commsFields);
  };

  const changeOutBoundSignal = (i, event) => {
    setIsUpdating(true);
    const values = [...outBoundFields];
    values[i].updatedBy = parseInt(userId);
    values[i].roomId = currentRoom.roomId;
    values[i][event.target.name] = event.target.value;
    let updatedRows = [...outboundRows];

    // Local path onchange event Outbound
    if (event.target.name === "crPathName") {
      if (event.target.value !== "") {
        values[i].crSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          crPathName: event.target.value,
        };
      } else {
        values[i].crSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          crPathName: event.target.value,
          routed: false,
        };
        onUpdateLiveShotSignals(
          token,
          values[i],
          { crPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setOutboundRows(updatedRows);
      setOutBoundFields(values);
      values[i].crPathNameSelected = "false";
    }

    // Set global path name outbound
    if (event.target.name === "globalPathName") {
      if (event.target.value !== "") {
        values[i].globalSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          globalPathName: event.target.value,
        };
      } else {
        values[i].globalSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          globalPathName: event.target.value,
          routed: false,
        };
        onUpdateLiveShotSignals(
          token,
          values[i],
          { globalPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setOutboundRows(updatedRows);
      setOutBoundFields(values);
      values[i].globalPathNameSelected = "false";
    }

    if (event.target.name === "availcheck") {
      if (event.target.value === "false") {
        values[i].availCheck = "true";
        onCheckAvailableSignals(
          token,
          sharedOutBoundGlobalPath,
          formInfo,
          currentCanvas,
          "OutBound"
        );
      } else {
        values[i].availCheck = "false";
      }
    }

    if (event.target.name === "uniLateral") {
      if (values[i].uniLateral === "true") {
        values[i].uniLateral = false;
        values[i].availCheck = "false";
      } else {
        values[i].uniLateral = true;
      }
      onUpdateUniLateral(token, values[i], currentRoom);
    }
    setOutBoundFields(values);
    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const onChangeOutboundLabel = (e, idx) => {
    let values = [...outBoundFields];
    values[idx].updatedBy = parseInt(userId);
    values[idx].pathName = e.target.value;
    let updatedRows = [...outboundRows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      pathName: e.target.value,
    };
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { pathName: e.target.value },
      currentRoom
    );
    setOutBoundFields(values);
    setOutboundRows(updatedRows);
    onChangeSignal(inBoundFields, values, commsFields);
  };

  const onChangeOutboundTransmissionPath = (e, idx) => {
    let values = [...outBoundFields];
    values[idx].updatedBy = parseInt(userId);
    let updatedRows = [...outboundRows];
    let targetValue = e.target.value;
    values[idx].tx = targetValue;
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { tx: targetValue },
      currentRoom
    );
    updatedRows[idx] = {
      ...updatedRows[idx],
      tx: targetValue,
    };
    setOutboundRows(updatedRows);
    setOutBoundFields(values);
    onChangeSignal(inBoundFields, values, commsFields);
  };

  const getSignalList = (signalIds = [2, 5, 7, 8, 9]) => {
    let signalList = [];
    signalList = removeDuplicates(liveShotSignalSelected).filter((signal) =>
      signalIds.includes(signal.liveshotSignalTypeId)
    );
    return signalList;
  };

  const handleOwnOutboundRadioClick = (idx, field, crPath) => {
    let updatedRows = [...outboundRows];
    let isRouted = updatedRows[idx].routed;

    if (isRouted) {
      unOwnLiveshotSignals(
        token,
        field.liveshotSignalId,
        field,
        "outbound",
        currentRoom
      );
    } else {
      if (isWindowTimeInvalid()) {
        return;
      }

      let isDuplicate = checkForDuplicateLocalPaths(
        field,
        getSignalList([2]),
        "globalPathName"
      );
      if (updatedRows.length > 0) {
        if (!isDuplicate) {
          field.signalType = "outbound";
          onTakeAndGrab(
            token,
            "grab",
            userId,
            userFullName,
            field,
            crPath,
            liveShotId,
            currentCanvas.roomId,
            currentRoom
          );
        } else {
          field.isDuplicate = true;
          field.confirmDuplicateRoute = confirmDuplicateRoute;
          field.crPath = crPath;
          field.btnType = "grabOutbound";
          field.signalType = "outbound";
          showDuplicateLocalPathModal(field);
        }
      }
    }
  };

  const addOutboundRow = () => {
    const newRow = {
      isShowOutboundTypeMenu: false,
      isOwnSelected: false,
      routed: false,
      outboundTypePlaceholder: "SELECT",
      pathName: "",
      tx: "",
      uniLateral: false,
    };
    setOutboundRows([...outboundRows, newRow]);
  };

  const tableHeaders = [
    {
      className: "empty-header",
    },
    { className: "outboundTypeHeaderCell", value: "Type" },
    { className: "availVcHeaderCell" },
    { className: "labelHeaderCell-outbound", value: "Label" },
    { className: "txpath", value: "Tx" },
    { className: "globalpath", value: "Global" },
    { className: "localHeaderCell", value: "Local" },
    { className: "unilatHeaderCell" },
    { className: "info-col" },
    { className: "ownHeaderCell", value: "Use" },
    { className: "useHeaderCell", value: "Own" },
  ];

  return (
    <div className="sends">
      <div className="signals-heading">
        {!isDisable && liveShotId !== 0 && (
          <div
            type="button"
            btnType="circle_btn"
            title="Add new Liveshot Signal"
            onClick={() => {
              onAddSignal(
                token,
                liveShotId,
                abstractLiveshotId,
                2,
                userId,
                currentRoom,
                "addOutbound"
              );
              addOutboundRow();
            }}
          >
            <InBoundPlusIcon className="outboundPlusIcon" alt="Plus Logo" />
          </div>
        )}
        <div className="pl-button">
          {!isDisable && liveShotId !== 0 && (
            <div
              type="button"
              btnType="circle_btn"
              title="Create Outbound based on Inbound"
              onClick={() =>
                !outBoundButton &&
                onAddSignal(
                  token,
                  liveShotId,
                  abstractLiveshotId,
                  2,
                  userId,
                  currentRoom,
                  "addOutbutton"
                )
              }
            >
              <OutboundLogo
                className={`outboundLogo ${outBoundButton ? "restricted" : ""}`}
                alt="Outbound Logo"
              />
            </div>
          )}
        </div>
        <label className="plus-btn">OUTBOUND PATHS</label>
      </div>
      <div className="outboundTableContainer">
        <table className="table table-borderless outboundTable">
          <thead>
            <tr className="outboundTableHeaderRow">
              {tableHeaders.map((header) => {
                return (
                  <th
                    scope="col"
                    key={header.className}
                    className={header.className}
                  >
                    {header.value}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {outBoundFields.map((field, idx) => {
              let crPathListOutBound = null;
              let globalPathListOutBound = null;
              let crPath = outBoundFieldsNormal.find(
                (outboundNormal) =>
                  outboundNormal.abstractLiveshotSignalId ===
                  field.liveshotSignalId
              );

              if (field.crSuggest === "true") {
                const filteredCROutBound = outBoundCRPath.filter((item) => {
                  return item
                    .toLowerCase()
                    .includes(field.crPathName.toLowerCase());
                });
                if (filteredCROutBound.length > 0) {
                  crPathListOutBound = (
                    <DropdownSuggestions
                      options={filteredCROutBound}
                      onSelect={(option) => {
                        setSignals(option, idx, "crPathOutBound");
                      }}
                    />
                  );
                }
              }

              if (field.globalSuggest === "true") {
                let filteredGlobalOutBound = outBoundGlobalPath.filter(
                  (globalPath) => {
                    return (
                      globalPath &&
                      globalPath
                        .toLowerCase()
                        .includes(
                          field.uniLateral
                            ? field.globalPathName &&
                                field.globalPathName.toLowerCase()
                            : crPath.globalPathName &&
                                crPath.globalPathName.toLowerCase()
                        )
                    );
                  }
                );

                if (field.availCheck === "true") {
                  filteredGlobalOutBound = availOutBoundSharedSignals.filter(
                    (globalPath) => {
                      return globalPath
                        .toLowerCase()
                        .includes(
                          field.uniLateral
                            ? field.globalPathName.toLowerCase()
                            : crPath.globalPathName.toLowerCase()
                        );
                    }
                  );
                }

                if (filteredGlobalOutBound.length > 0) {
                  globalPathListOutBound = (
                    <DropdownSuggestions
                      options={filteredGlobalOutBound}
                      onSelect={(option) => {
                        setSignals(option, idx, "globalPathOutBound");
                      }}
                    />
                  );
                }
              }

              let outBoundCrPathInfo = null;
              let crTitle = null;
              if (crPath) {
                outBoundCrPathInfo = outBoundCRPath.find(
                  (item) => item === crPath.crPathName
                );
              }

              let isRedBorder = null;
              if (redBorder.length > 0) {
                isRedBorder = redBorder.find(
                  (item) => item.liveshotSignalId === field.liveshotSignalId
                );
              }

              let outBoundGlobalPathInfo = null;
              let globalTitle = null;
              if (field) {
                outBoundGlobalPathInfo = outBoundGlobalPath.find(
                  (item) => item === field.globalPathName
                );
              }

              if (!outBoundCrPathInfo && crPath) {
                crTitle = "Field is empty";
                if (crPath.crPathName !== "") {
                  crTitle = "Name not found in signal list";
                }
              } else if (!isDisable) {
                outBound_crPath_classes = ["inboundOutboundInputFields"];
              } else {
                outBound_crPath_classes = ["disable-input"];
              }

              if (!outBoundGlobalPathInfo) {
                if (
                  !outBoundGlobalPathInfo &&
                  !field.uniLateral &&
                  crPath &&
                  (crPath.globalPathName === null ||
                    crPath.globalPathName === "")
                ) {
                  globalTitle = "Field is empty";
                } else if (field.uniLateral) {
                  globalTitle = "Field is empty";
                  if (field.globalPathName) {
                    globalTitle = "Name not found in signal list";
                  }
                } else if (!isDisable) {
                  outBound_glPath_classes = ["inboundOutboundInputFields"];
                } else {
                  outBound_glPath_classes = ["disable-input"];
                }
              } else if (isRedBorder) {
                outBound_glPath_classes = ["red-border"];
                let date = new Date(isRedBorder.controlTime);
                let hours = "" + date.getHours();
                if (hours.length < 2) hours = "0" + hours;
                let minutes = "" + date.getMinutes();
                if (minutes.length < 2) minutes = "0" + minutes;
                let endDate = new Date(isRedBorder.windowEndTime);
                let endHours = "" + endDate.getHours();
                if (endHours.length < 2) endHours = "0" + endHours;
                let endMinutes = "" + endDate.getMinutes();
                if (endMinutes.length < 2) endMinutes = "0" + endMinutes;
                let userName;
                const userNameList = userList.find(
                  (element) => element.sso === parseInt(isRedBorder.updatedBy)
                );
                if (userNameList !== undefined) {
                  userName = userNameList.userName;
                } else {
                  userName = parseInt(isRedBorder.updatedBy);
                }
                globalTitle =
                  "This signal is reserved by " +
                  userName +
                  " for " +
                  isRedBorder.liveshotName +
                  " between " +
                  hours +
                  ":" +
                  minutes +
                  "-" +
                  endHours +
                  ":" +
                  endMinutes;
              } else if (!isDisable) {
                outBound_glPath_classes = ["inboundOutboundInputFields"];
              } else {
                outBound_glPath_classes = ["disable-input"];
              }

              if (
                sharedOutBoundGlobalPath.includes(outBoundGlobalPathInfo) &&
                formInfo &&
                !formInfo.abstractLiveshot.windowEndTime
              ) {
                outBound_glPath_classes = ["red-border"];
                globalTitle = "Window-end must be set for shared resources";
              }

              if (
                outBoundGlobalPathInfo &&
                !field.uniLateral &&
                crPath &&
                (crPath.globalPathName === null || crPath.globalPathName === "")
              ) {
                outBound_glPath_classes = ["red-border"];
                globalTitle = "Field is empty";
              }
              let localPath = field.crPathName;
              let globalPath = field.globalPathName;
              let localPathNameError = field.crPathNameError;
              let globalPathNameError = field.globalPathNameError;
              let isOutboundOwnDisabled = !(
                isArmRouting &&
                (liveshotStatus === "READY FOR AIR" ||
                  liveshotStatus === "READY FOR PCR") &&
                localPath &&
                globalPath &&
                !localPathNameError &&
                !globalPathNameError
              );
              let isOwnSelected =
                outboundRows && outboundRows[idx] && outboundRows[idx].routed;
              return (
                <tr key={`${field}-${idx}`} className={field.liveshotSignalId}>
                  <td>
                    {!isDisable && (
                      <div
                        type="button"
                        btnType="circle_btn removeOutBoundSignal"
                        title="Remove Liveshot Signal"
                        onClick={() => {
                          if (isOwnSelected) {
                            console.log("un own and delete");
                            showCommonPopupModal({
                              showModal: true,
                              title: "Warning",
                              content:
                                "Please un-own the signal before deleting it.",
                              type: "warning",
                            });
                          } else {
                            deleteSignal(
                              field.liveshotSignalId,
                              crPath ? crPath.liveshotSignalId : null
                            );
                            removeOutboundRow(idx);
                          }
                        }}
                      >
                        <MinusLogo alt="Minus Logo" className="minusIcon" />
                      </div>
                    )}
                  </td>
                  <td className="outboundTypeCell">
                    <div
                      className={`typeDropdownContainer ${
                        outboundRows &&
                        outboundRows[idx] &&
                        outboundRows[idx].isShowOutboundTypeMenu
                          ? "greenBorder"
                          : ""
                      }`}
                    >
                      <div
                        className={`select-type placeholderDiv ${
                          isDisable ? "restricted " : ""
                        }`}
                        ref={(el) => (outBoundTypeMenu.current[idx] = el)}
                        value={type ? type : ""}
                        onChange={(e) => setStype(e.target.value)}
                        onClick={() => {
                          if (!isDisable) {
                            hideOutboundTypes(idx);
                          }
                        }}
                      >
                        <div
                          className={`wrap-text ${
                            field.type ? "displayNone" : "showPlaceholder"
                          } ${
                            outboundRows &&
                            outboundRows[idx] &&
                            outboundRows[idx].isShowOutboundTypeMenu
                              ? "greenColor"
                              : ""
                          }`}
                        >
                          {outboundRows &&
                            outboundRows[idx] &&
                            outboundRows[idx].outboundTypePlaceholder}
                        </div>
                        <div
                          className={`wrap-text info ${
                            outboundRows &&
                            outboundRows[idx] &&
                            outboundRows[idx].isShowOutboundTypeMenu
                              ? "greenColor"
                              : ""
                          }`}
                        >
                          {field.type}
                        </div>
                        <i
                          style={
                            outboundRows &&
                            outboundRows[idx] &&
                            outboundRows[idx].isShowOutboundTypeMenu
                              ? { display: "none" }
                              : { display: "block" }
                          }
                          className={`fas fa-angle-down outboundDrop ${
                            !field.type ? "grey7Color" : ""
                          }`}
                        ></i>
                        <i
                          style={
                            !(
                              outboundRows &&
                              outboundRows[idx] &&
                              outboundRows[idx].isShowOutboundTypeMenu
                            )
                              ? { display: "none" }
                              : { display: "block" }
                          }
                          className={`fas fa-angle-up venuedrop ${
                            outboundRows &&
                            outboundRows[idx] &&
                            outboundRows[idx].isShowOutboundTypeMenu
                              ? "greenColor"
                              : ""
                          }`}
                        ></i>
                      </div>
                    </div>
                    {outboundRows &&
                      outboundRows[idx] &&
                      outboundRows[idx].isShowOutboundTypeMenu && (
                        <ClickAwayListenerHoc
                          elementToIgnore={"outboundTypeOption"}
                          key={`clickawayoutbound-${idx}`}
                          nodeRef={
                            outBoundTypeMenu.current &&
                            outBoundTypeMenu.current[idx]
                          }
                          hideElement={() => {
                            hideDropdownOnClickOutside(
                              outboundRows,
                              setOutboundRows,
                              idx,
                              { isShowOutboundTypeMenu: false }
                            );
                          }}
                        >
                          <div className="list-container">
                            <ul
                              name="outboundTypeOptions"
                              className="ul-list-item-container"
                            >
                              {outBoundTypeArray &&
                                outBoundTypeArray.map((item) => (
                                  <li
                                    name="outboundTypeOption"
                                    className={
                                      field.type == item
                                        ? "ul-list-item list-item-bg"
                                        : "ul-list-item"
                                    }
                                    key={item}
                                    value={item}
                                    onClick={(e) => {
                                      onChangeOutboundType(e, idx);
                                      changeOutBoundSignal(idx, e);
                                    }}
                                  >
                                    {item}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </ClickAwayListenerHoc>
                      )}
                  </td>
                  <td className="availVcCell-outbound"></td>
                  <td className="labelCell">
                    <DebounceInput
                      debounceTimeout={1500}
                      element={CustomInput}
                      placeholder="ENTER"
                      type="text"
                      name="pathName"
                      value={
                        outboundRows[idx] ? outboundRows[idx].pathName : ""
                      }
                      className={`inboundOutboundInputFields ${
                        !isDisable ? "edit-input" : "disable-input"
                      }`}
                      disabled={isDisable}
                      onChange={(e) => onChangeOutboundLabel(e, idx)}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                  </td>
                  <td className="labelCell">
                    <DebounceInput
                      debounceTimeout={1500}
                      element={CustomInput}
                      type="text"
                      placeholder="ENTER"
                      name="tx"
                      autoComplete="off"
                      className={`inboundOutboundInputFields ${
                        !isDisable ? "outboundTxPath" : "disable-input"
                      }`}
                      value={outboundRows[idx] ? outboundRows[idx].tx : ""}
                      onChange={(e) => onChangeOutboundTransmissionPath(e, idx)}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      disabled={isDisable}
                    />
                  </td>
                  <td className="globalpathCell labelCell">
                    {field.uniLateral ? (
                      <DebounceInput
                        title={globalTitle}
                        element={CustomInput}
                        type="text"
                        placeholder="ENTER"
                        name="globalPathName"
                        autoComplete="off"
                        className={
                          `inboundOutboundInputFields globalpathField ${outBound_glPath_classes.join(
                            ""
                          )}` + getPathClassName(field.globalPathNameError)
                        }
                        value={
                          outboundRows[idx]
                            ? outboundRows[idx].globalPathName
                            : ""
                        }
                        onChange={(e) => changeOutBoundSignal(idx, e)}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        disabled={isDisable}
                      />
                    ) : (
                      <DebounceInput
                        title={globalTitle}
                        element={CustomInput}
                        type="text"
                        placeholder="ENTER"
                        name="globalPathName"
                        key={`globalPathName${field.globalKey}`}
                        autoComplete="off"
                        className={
                          `inboundOutboundInputFields globalpathField ${outBound_glPath_classes.join(
                            ""
                          )}` + getPathClassName(field.globalPathNameError)
                        }
                        value={
                          outboundRows[idx]
                            ? outboundRows[idx].globalPathName
                            : ""
                        }
                        onChange={(e) => changeOutBoundSignal(idx, e)}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        disabled={isDisable}
                      />
                    )}

                    {globalPathListOutBound}
                  </td>
                  <td className="localCell labelCell">
                    <DebounceInput
                      title={crTitle}
                      element={CustomInput}
                      type="text"
                      name="crPathName"
                      key={`crPathName${field.crKey}`}
                      placeholder="ENTER"
                      autoComplete="off"
                      className={
                        `inboundOutboundInputFields crPathField ${outBound_crPath_classes.join(
                          ""
                        )}` + getPathClassName(field.crPathNameError)
                      }
                      value={
                        outboundRows[idx] ? outboundRows[idx].crPathName : ""
                      }
                      onChange={(e) => changeOutBoundSignal(idx, e)}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      disabled={isDisable}
                    />
                    {crPathListOutBound}
                  </td>
                  <td className="unilateral"></td>
                  <td className="inbound-info-cell">
                    <Tooltip
                      message={updateInfo(field)}
                      position={"bottom"}
                      title="Update Info"
                      messageTheme="light-theme"
                      titleTheme="light-theme"
                    >
                      <InfoLiveShotOutlineLogo
                        className="update-info-icon-outbound"
                        alt="info"
                      />
                    </Tooltip>
                  </td>
                  <td className="use-radio-input-cell-outbound">
                    <span className="useRadioOutbound">
                      <span className={`useRadio`}>
                        <div
                          className="useOutboundRadioDiv"
                          aria-hidden="true"
                        ></div>
                      </span>
                    </span>
                  </td>
                  <td className="own-radio-input-cell">
                    <span
                      className={`ownRadio ${
                        isOutboundOwnDisabled || isDisable
                          ? "cursorNotAllowed"
                          : "radio-btn-allowed"
                      }`}
                    >
                      <div
                        onClick={() => {
                          handleOwnOutboundRadioClick(idx, field, crPath);
                        }}
                        className={`ownOutboundRadioDiv ${
                          isOutboundOwnDisabled || isDisable
                            ? "cursorNotAllowed disableField"
                            : isOwnSelected
                            ? "mint6border"
                            : "hoverState"
                        }`}
                      >
                        <div
                          className={
                            isOwnSelected
                              ? "radioFill activeFill"
                              : "radioHover"
                          }
                        ></div>
                      </div>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    currentCanvasId: state.canvasInfo.currentCanvasId,
    currentCanvas: state.canvasInfo.currentCanvas,
    controlRoomList: state.canvasInfo.controlRoomList,
    commCapabilityType: state.canvasInfo.commCapabilityType,
    inBoundCRPath: state.signalsInfo.inBoundCRPath,
    inBoundGlobalPath: state.signalsInfo.inBoundGlobalPath,
    inBoundExtendGlobalPath: state.signalsInfo.inBoundExtendGlobalPath,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    availSignal: state.signalsInfo.signal,
    pinObj: state.signalsInfo.pinNumber,
    outBoundCRPath: state.signalsInfo.outBoundCRPath,
    outBoundGlobalPath: state.signalsInfo.outBoundGlobalPath,
    ifbCRPath: state.signalsInfo.ifbCRPath,
    ifbGlobalPath: state.signalsInfo.ifbGlobalPath,
    plGlobalPath: state.signalsInfo.plGlobalPath,
    plCRPath: state.signalsInfo.plCRPath,
    phoGlobalPath: state.signalsInfo.phoGlobalPath,
    phoCRPath: state.signalsInfo.phoCRPath,
    phoTxCRPath: state.signalsInfo.phoTxCRPath,
    phoTxGlobalPath: state.signalsInfo.phoTxGlobalPath,
    globalPins: state.signalsInfo.globalPins,
    grabbedSignals: state.signalsInfo.grabbedSignals,
    signalMsg: state.signalsInfo.signalMsg,
    userList: state.user.userList,
    isArmRouting: state.canvasInfo.armRouting,
    redBorder: state.signalsInfo.redBorder,
    sharedInBoundGlobalPath: state.signalsInfo.sharedInBoundGlobalPath,
    sharedOutBoundGlobalPath: state.signalsInfo.sharedOutBoundGlobalPath,
    sharedIFBGlobalPath: state.signalsInfo.sharedIFBGlobalPath,
    sharedPLGlobalPath: state.signalsInfo.sharedPLGlobalPath,
    sharedPHOGlobalPath: state.signalsInfo.sharedPHOGlobalPath,
    sharedPHOTXGlobalPath: state.signalsInfo.sharedPHOTXGlobalPath,
    availInBoundSharedSignals: state.signalsInfo.availInBoundSharedSignals,
    availOutBoundSharedSignals: state.signalsInfo.availOutBoundSharedSignals,
    availIFBSharedSignals: state.signalsInfo.availIFBSharedSignals,
    availPLSharedSignals: state.signalsInfo.availPLSharedSignals,
    availPHOSharedSignals: state.signalsInfo.availPHOSharedSignals,
    loading: state.routerSourceDestination.loading,
    stDefaultPinInfo: state.studioTruckInfo.stDefaultPinInfo,
    routerSources: state.routerSourceDestination.routerSources,
    studioTruckTypes: state.studioTruckInfo.studioTruckTypes,
    studioTruckData: state.studioTruckInfo.studioTruckData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateLiveShotSignal: (
      token,
      lsId,
      ablsId,
      lsSignalTypeId,
      userId,
      room,
      venueType
    ) =>
      dispatch(
        actions.createLiveShotSignal(
          token,
          lsId,
          ablsId,
          lsSignalTypeId,
          userId,
          room,
          venueType
        )
      ),
    onUpdateLiveShotSignals: (
      token,
      signalInfo,
      signalInfoObj,
      room,
      updateCommGlobal = false,
      needUpdate = false
    ) =>
      dispatch(
        actions.updateLiveShotSignals(
          token,
          signalInfo,
          signalInfoObj,
          room,
          updateCommGlobal,
          needUpdate
        )
      ),
    onUpdateUniLateral: (token, signalInfo, room) =>
      dispatch(actions.updateUniLateral(token, signalInfo, room)),
    onUpdateNormalLiveShotSignals: (token, signalInfo, room) =>
      dispatch(actions.updateNormalLiveShotSignals(token, signalInfo, room)),
    onTakeAndGrab: (
      token,
      control,
      userId,
      userFullName,
      abstractSignalInfo,
      normalSignalInfo,
      liveshotId,
      roomId,
      currentRoom,
      globalPathRoomId
    ) =>
      dispatch(
        actions.takeAndGrab(
          token,
          control,
          userId,
          userFullName,
          abstractSignalInfo,
          normalSignalInfo,
          liveshotId,
          roomId,
          currentRoom,
          globalPathRoomId
        )
      ),
    onRemoveRedBorder: (signalId) =>
      dispatch(actions.removeRedBorder(signalId)),
    onCheckOwnership: (token, signal, formInfo, currentCanvas) =>
      dispatch(actions.checkOwnership(token, signal, formInfo, currentCanvas)),
    onCheckAvailableSignals: (token, signals, formInfo, currentCanvas, type) =>
      dispatch(
        actions.checkAvailableSignals(
          token,
          signals,
          formInfo,
          currentCanvas,
          type
        )
      ),
    showDuplicateLocalPathModal: (res) =>
      dispatch(actions.showSignalModal(res)),
    unOwnLiveshotSignals: (
      token,
      liveshotSignalId,
      field,
      pathKey,
      currentRoom,
      isDuplicate = false,
      grabParams = {}
    ) =>
      dispatch(
        actions.unOwnRoute(
          token,
          liveshotSignalId,
          field,
          pathKey,
          currentRoom,
          isDuplicate,
          grabParams
        )
      ),
    showCommonPopupModal: (modalDetail) =>
      dispatch(actions.showCommonPopupModal(modalDetail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutboundSignals);
